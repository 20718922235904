import React from "react";
import Marquee from "react-fast-marquee";

import FoxSports1 from "../images/publications/fox-sports-1.svg";
import BallySportsWest from "../images/publications/bally-sports-west.svg";
import Discovery from "../images/publications/discovery.svg";
import SpectrumSportsnetLA from "../images/publications/spectrum-sportsnet-la.svg";
import Cnbc from "../images/publications/cnbc.svg";
import Espn from "../images/publications/espn.svg";
import GolfChannel from "../images/publications/golf-channel.svg";
import TntChannel from "../images/publications/tnt-channel.svg";
import Cnn from "../images/publications/cnn.svg";
import Espn2 from "../images/publications/espn2.svg";
import HgtvUs from "../images/publications/hgtv-us.svg";
import TvLand from "../images/publications/tv-land.svg";
import ComedyCentral from "../images/publications/comedy-central.svg";
import FoodNetwork from "../images/publications/food-network.svg";
import Msnbc from "../images/publications/msnbc.svg";
import UsaNetwork from "../images/publications/usa-network.svg";

function PublicationsMarquee() {
  return (
    <div className="text-center mt-14">
        <div className="mb-4">
            <h2 className="text-3xl font-bold text-gray-800">As seen on...</h2>
        </div>
        <Marquee className="py-10">
            <img src={FoxSports1} alt="Fox Sports Logo" className="h-10 mx-10" />
            <img src={BallySportsWest} alt="Bally Sports West Logo" className="h-12 mx-10" />
            <img src={Discovery} alt="Discovery Logo" className="h-8 mx-10" />
            <img src={SpectrumSportsnetLA} alt="Spectrum Sportsnet LA Logo" className="h-8 mx-10" />
            <img src={Cnbc} alt="CNBC Logo" className="h-12 mx-10" />
            <img src={Espn} alt="ESPN Logo" className="h-8 mx-10" />
            <img src={GolfChannel} alt="Golf Channel Logo" className="h-10 mx-10" />
            <img src={TntChannel} alt="TNT Channel Logo" className="h-14 mx-10" />
            <img src={Cnn} alt="CNN Logo" className="h-8 mx-10" />
            <img src={Espn2} alt="ESPN2 Logo" className="h-8 mx-10" />
            <img src={HgtvUs} alt="HGTV US Logo" className="h-10 mx-10" />
            <img src={TvLand} alt="TV Land Logo" className="h-8 mx-10" />
            <img src={ComedyCentral} alt="Comedy Central Logo" className="h-10 mx-10" />
            <img src={FoodNetwork} alt="Food Network Logo" className="h-14 mx-10" />
            <img src={Msnbc} alt="MSNBC Logo" className="h-8 mx-10" />
            <img src={UsaNetwork} alt="USA Network Logo" className="h-8 mx-10" />
        </Marquee>
    </div>
  );
};

// Export your component with the unique name
export default PublicationsMarquee;