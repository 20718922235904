import React from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import Img1 from '../images/homepage/1.jpg'
import Img2 from '../images/homepage/2.jpg'
import Img3 from '../images/homepage/3.jpg'

const Slides = ({ children }) => {
  return (
    <div className="relative w-full">
     <Splide 
      options={ {
        rewind: true,
        autoplay: true,
        type  : 'loop',
        speed : 1000,
        width : '100%',
        arrows : false,
      } }
     >
        <SplideSlide>
          <img src={Img1} alt="Image 1" />
        </SplideSlide>
        <SplideSlide>
          <img src={Img3} alt="Image 3" />
        </SplideSlide>
        <SplideSlide>
          <img src={Img2} alt="Image 2" />
        </SplideSlide>
      </Splide>
    </div>
  );
};

export default Slides;