import React from "react"
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const SlidesTestimonials = ({ children }) => {
  return (
    <div className="text-center w-3/4 relative">
      <Splide 
        options={ {
          rewind: true,
          autoplay: true,
          type  : 'fade',
          speed : 800,
          width : '100%',
          gap   : '1rem',
          arrows : false,
          pauseOnHover : false,
          pagination: false
        } }
      >
          <SplideSlide>
            <div className="py-10">
              <h2 className="before:content-['“_'] before:text-7xl lg:before:text-8xl before:-mt-5 before:-ml-10 before:absolute before:font-light after:content-['_”'] after:text-7xl lg:after:text-8xl after:pt-0 after:pl-1 after:absolute after:font-light text-lg leading-6 font-bold lg:text-3xl m-10 lg:p-10 lg:leading-9">Concentration and mental toughness are the margins of victory.</h2>
              <p className="my-10 font-medium text-md lg:text-xl">Bill Russell</p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="py-10">
              <h2 className="before:content-['“_'] before:text-7xl lg:before:text-8xl before:-mt-5 before:-ml-10 before:absolute before:font-light after:content-['_”'] after:text-7xl lg:after:text-8xl after:pt-0 after:pl-1 after:absolute after:font-light text-lg leading-6 font-bold lg:text-3xl m-10 lg:p-10 lg:leading-9">Mental toughness is essential to success.</h2>
              <p className="my-10 font-medium text-md lg:text-xl">Vince Lombardi</p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <h2 className="before:content-['“_'] before:text-7xl lg:before:text-8xl before:-mt-5 before:-ml-10 before:absolute before:font-light after:content-['_”'] after:text-7xl lg:after:text-8xl after:pt-0 after:pl-1 after:absolute after:font-light text-lg leading-6 font-bold lg:text-3xl m-10 lg:p-10 lg:leading-9">You show me, anybody that's great in anything they do, I'll show you, somebody that's persevered, demonstrated that mental toughness to overcome some obstacles and adversity.</h2>
            <p className="my-10 font-medium text-md lg:text-xl">Sean McVay</p>
          </SplideSlide>
          <SplideSlide>
            <h2 className="before:content-['“_'] before:text-7xl lg:before:text-8xl before:-mt-5 before:-ml-10 before:absolute before:font-light after:content-['_”'] after:text-7xl lg:after:text-8xl after:pt-0 after:pl-1 after:absolute after:font-light text-lg leading-6 font-bold lg:text-3xl m-10 lg:p-10 lg:leading-9">You have to want it, you have to plan for it, you have to fit it into a busy day, you have to be mentally tough, you have to use others to help you. The hard part isn't getting your body in shape. The hard part is getting your mind in shape.</h2>
            <p className="my-10 font-medium text-md lg:text-xl">Amby Burfoot</p>
          </SplideSlide>
          <SplideSlide>
            <div className="py-10">
              <h2 className="before:content-['“_'] before:text-7xl lg:before:text-8xl before:-mt-5 before:-ml-10 before:absolute before:font-light after:content-['_”'] after:text-7xl lg:after:text-8xl after:pt-0 after:pl-1 after:absolute after:font-light text-lg leading-6 font-bold lg:text-3xl m-10 lg:p-10 lg:leading-9">The most important attribute a player must have is mental toughness.</h2>
              <p className="my-10 font-medium text-md lg:text-xl">Mia Hamm</p>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="py-8">
              <h2 className="before:content-['“_'] before:text-7xl lg:before:text-8xl before:-mt-5 before:-ml-10 before:absolute before:font-light after:content-['_”'] after:text-7xl lg:after:text-8xl after:pt-0 after:pl-1 after:absolute after:font-light text-lg leading-6 font-bold lg:text-3xl m-10 lg:p-10 lg:leading-9">Every quarterback can throw a ball; every running back can run; every receiver is fast, but that mental toughness that you talk about translates into competitiveness.</h2>
              <p className="my-10 font-medium text-md lg:text-xl">Tom Brady</p>
            </div>
          </SplideSlide>
        </Splide>
      </div>
  );
};

export default SlidesTestimonials;