import * as React from "react"
import GoCard from '../images/go-tm-card.svg'
import GoCardRunner from '../images/go-tm-card-runner.png'
import PublicationsMarquee from '../components/PublicationsMarquee'
import Slides from '../components/Slides'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Professional1 from '../images/professionals/professional-1.png'
import Professional2 from '../images/professionals/professional-2.png'
import Professional3 from '../images/professionals/professional-3.png'
import Professional4 from '../images/professionals/professional-4.png'
import Professional5 from '../images/professionals/professional-5.png'
import Professional6 from '../images/professionals/professional-6.png'
import GoBlockImg1 from '../images/go-block-1.png'
import GoBlockImg2 from '../images/go-block-2.png'
import potentialIcon from '../images/icons/icon-potential.svg'
import videoConferenceIcon from '../images/icons/icon-video-conference.svg'
import payPerClick from '../images/icons/icon-pay-per-click.svg'
import singUps from '../images/icons/icon-singup.svg'
import promos from '../images/icons/icons-promos.svg'
import videoCalls from '../images/icons/icons-video-calls.svg'
import multipUsersGrid from '../images/multiple-users-grid.png'
import gameOnAppIphone from '../images/app/gameon-iphone-app.png'
import appleAppStore from '../images/app/apple-store-badge.svg'
import googleAppStore from '../images/app/google-android-badge.svg'
import iphoneApp from '../images/app/iphone-app.png'
import SlidesTestimonials from "../components/SlidesTestimonials"

const IndexPage = () => (
  <Layout isHomePage={true}>
    <div className="mb-20">
      
      <div className="w-full mx-auto">
        <div className="grid grid-cols-1 lg:grid-cols-6">
          <div className="w-full lg:col-span-3 lg:grid-cols-3 flex justify-center items-center">
            <div className="col-span-1 lg:col-span-2">
              <Slides />
            </div>
            <div />
          </div>

          <div className="flex items-center justify-center col-span-4 lg:col-span-3 py-20 lg:px-0">
            <div className="text-left">
              <h3 className="font-bold text-4xl lg:text-5xl my-5 text-gameOnDarkGreen">It begins with <span className="text-gameOnGreen">you</span></h3>
              <p className="font-medium text-lg leading-6 lg:leading-none lg:text-xl text-gray-600">Start strengthening your mental skills today!</p>
              <div className="my-5">
                <a href="http://web.thegameonapp.com/">
                    <button className=" w-full lg:w-auto px-20 py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent lg:mt-0 bg-gameOnDarkGreen hover:bg-gameOnDarkGreenActive">Sign Up</button>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="bg-gameOnDarkGreen text-white">
        <div className="container mx-auto lg:p-28">
          <div className="flex items-center justify-center">
            <SlidesTestimonials />
          </div>
        </div>
      </div>

      <div className="mb-10 lg:mb-20" />

      <div className="grid grid-cols-1 lg:grid-cols-3 py-5">
        <div className="grid lg:grid-rows-2  grid-cols-2 lg:grid-flow-col gap-4 px-5">
          <img src={Professional1} className="w-full rounded-3xl" />
          <img src={Professional2} className="w-full rounded-3xl" />
          <img src={GoBlockImg1} className="w-full rounded-3xl" />
          <img src={Professional3} className="w-full rounded-3xl" />
        </div>
        <div className="grid grid-rows-3 py-5 lg:py-0 lg:grid-flow-col gap-4 px-5">
          <div className="">
            <h2 className="font-bold text-2xl lg:text-3xl text-gameOnDarkGreen text-left">What we offer</h2>
            <p className="leading-5 lg:leading-6 font-medium  text-md lg:text-lg my-5 text-gray-600 text-left">Customized mental skills training that compliments who you are and fits within your schedule, timeline, goals, and life.</p>
          </div>
          <div className="">
            <h2 className="font-bold text-2xl lg:text-3xl text-gameOnDarkGreen lg:text-right">Who we serve</h2>
            <p className="leading-5 lg:leading-6 font-medium  text-md lg:text-lg my-5 text-gray-600 lg:text-right">Anyone who is 18 years and older. Any profession. All performance targets,  areas of improvement and focus.</p>
          </div>
          <div className="">
            <h2 className="font-bold  text-2xl lg:text-3xl text-gameOnDarkGreen text-left">What we believe</h2>
            <p className="leading-5 lg:eading-6 font-medium t text-md lg:text-lg my-5 text-gray-600 text-left">Our mental performance coaches are ready to bridge the gap between where you are and where you can be.</p>
          </div>
        </div>
        <div className="grid lg:grid-rows-2  grid-cols-2 lg:grid-flow-col gap-4 px-5">
          <img src={Professional4} className="w-full rounded-3xl" />
          <img src={GoBlockImg2} className="w-full rounded-3xl" />
          <img src={Professional5} className="w-full rounded-3xl" />
          <img src={Professional6} className="w-full rounded-3xl" />
        </div>
      </div>

      <div className="border-b border-gameOnGray my-10 lg:my-20" />

      <div className="text-center bg-gameOnLightGreen rounded-xl p-10 bg-bgTexture bg-cover bg-center mx-5 lg:mx-10">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="grid gap-4 p-5">   
            <img src={potentialIcon} className="w-24 mx-auto" />
            <div className="">
              <h3 className="mb-3 font-bold text-lg text-gameOnDarkGreen">Choose a Professional</h3>
              <p className="leading-5 font-sm text-gray-600">Your health, your choice. Choose the perfect personality to meet your needs from our catalog of professionals.</p>
            </div>
          </div>
          <div className="grid gap-4 p-5">
            <img src={videoConferenceIcon} className="w-24 mx-auto" />
            <div className="">
              <h3 className="mb-3 font-bold text-lg text-gameOnDarkGreen">Communicate via Text & Voice/Video Call</h3>
              <p className="leading-5 font-sm text-gray-600">We are most comfortable with whatever communication channel you are most comfortable with—and so are our professionals!</p>
            </div>
          </div>
          <div className="grid gap-4 p-5">
            <img src={payPerClick} className="w-24 mx-auto" />
            <div className="">
              <h3 className="mb-3 font-bold text-lg text-gameOnDarkGreen">Simple and Affordable Pricing</h3>
              <p className="leading-5 font-sm text-gray-600">We offer flexible payment plans to meet diverse needs because we believe that reliable mental health care should not burn a hole in your pocket.</p>
            </div>
          </div>
        </div>
        <div className="py-10">
          <a href="http://web.thegameonapp.com/">
            <button className="px-20 w-full lg:w-auto py-5 leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent lg:mt-0 bg-gameOnDarkGreen hover:bg-gameOnDarkGreenActive">Sign Up</button>
          </a>
        </div>
      </div>

      <div className="border-b border-gameOnGray my-10 lg:my-20" />

      <div className="text-center lg:p-10 mx-5 lg:mx-10">
        <h2 className="font-bold text-2xl lg:text-3xl text-gameOnDarkGreen text-left mb-5">Improve your quality of life in 3 steps:</h2>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="p-5 grid grid-cols-2 lg:grid-cols-none">
            <img src={singUps} className="" />  
            <h3 className="font-bold py-3 text-md leading-5 lg:leading-none lg:text-xl text-gameOnDarkGreen text-left">Sign up via our website or mobile app</h3>
          </div>        
          <div className="p-5 grid grid-cols-2 lg:grid-cols-none">
            <img src={promos} className="" />  
            <h3 className="font-bold py-3 text-md leading-5 lg:leading-none lg:text-xl text-gameOnDarkGreen text-left">Choose the right subscription plan for you</h3>
          </div> 
          <div className="p-5 grid grid-cols-2 lg:grid-cols-none">
            <img src={videoCalls} className="" />  
            <h3 className="font-bold py-3 text-md leading-5 lg:leading-none lg:text-xl text-gameOnDarkGreen text-left">Book a session with a mental wellness coach or counselor</h3>
          </div> 
        </div>
        <div className="py-10">
          <a href="http://web.thegameonapp.com/">
            <button className="px-20 py-5 w-full lg:w-auto leading-none text-white font-outfit font-medium text-base rounded-3xl hover:border-transparent lg:mt-0 bg-gameOnDarkGreen hover:bg-gameOnDarkGreenActive">Sign Up</button>
          </a>
        </div>
      </div>

      <div className="my-5">
        <img src={multipUsersGrid} className="w-full" />
      </div>

      <div className="border-b border-gameOnGray my-10 lg:my-20" />

      <div className=" bg-gameOnLightGreen rounded-3xl bg-bgTexture bg-cover bg-center mx-5 lg:mx-10">
        <div className="grid  grid-cols-1 lg:grid-cols-2 gap-4">
          <div className="flex gap-8 items-center justify-center p-5 lg:px-20">
            <div className="">
              <h3 className="font-bold text-4xl text-gameOnDarkGreen text-left mb-5">Mental wellness support, anywhere!</h3>
              <p className="font-medium text-lg lg:text-xl text-gray-600">Take Game On! with you everywhere by downloading our mobile app.  Quality mental wellness, all at the touch of a screen.</p>
              <p className="mt-20">
                <h4 className=" font-bold text-lg text-gameOnDarkGreen">Get the App</h4>
                <ul className="grid grid-cols-2 lg:grid-cols-3 gap-4 py-5">
                  <li>
                    <a href="https://apps.apple.com/us/app/game-on-app-llc/id6443446110" target="_blank">
                      <img src={appleAppStore} className="w-40" />
                    </a>
                  </li>
                  <li>
                    <a href="https://play.google.com/store/apps/details?id=com.gameonllc&hl=en&gl=US&pli=1" target="_blank">
                      <img src={googleAppStore} className="w-40" />
                    </a>
                  </li>
                </ul>
              </p>
            </div>
          </div>
          <div className="">             
            <img src={iphoneApp} className="w-full rounded-bl-3xl rounded-br-3xl inline-block lg:hidden" />
            <img src={gameOnAppIphone} className="w-full hidden lg:inline-block" />
          </div>
        </div>
      </div>


      <div className="border-b border-gameOnGray my-10 lg:my-20" />

      <PublicationsMarquee />

    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Game On! | Access quality mental wellness services in a safe digital environment." />

export default IndexPage
